<template>
    <div class="content-body">
        <div class="header">
            <el-button type="primary" @click="addFanCharacteristics"><i class="iconfont">&#xe609;</i>添加粉丝特征</el-button>
        </div>
        <el-table class="content-table customTable" :data="fanCharacteristicsList" style="width: 100%; margin-top: 20px; flex: 1;" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                  :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
            <el-table-column prop="name" align="left" label="粉丝特征名称"></el-table-column>
            <el-table-column align="center" label="操作" width="150">
                <template slot-scope="scope">
                    <el-link type="success" :underline="false" @click="editFanCharacteristics(scope.row.id)">编辑</el-link>
                    <el-link type="danger" :underline="false" @click="delFanCharacteristics(scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin: 20px 0;text-align: center"
                       :current-page="fanCharacteristicsPages.currentPageNum"
                       :page-size="fanCharacteristicsPages.eachPageNum"
                       :total="fanCharacteristicsPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="fanCharacteristicsCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {fanList, fanDel} from '@/utils/apis'

    export default {
        name: "FanCharacteristics",
        data() {
            return {
                //粉丝特征列表
                fanCharacteristicsList: [],
                //分页
                fanCharacteristicsPages:{
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            this.getFanCharacteristicsList();
        },
        methods: {
            //获取粉丝特征列表
            getFanCharacteristicsList() {
                let param = {
                    paging: 1,
                    pageSize: this.fanCharacteristicsPages.eachPageNum,
                    page: this.fanCharacteristicsPages.currentPageNum
                }
                fanList(param).then((res) => {
                    this.fanCharacteristicsList = res.data.data;
                    this.fanCharacteristicsPages.total = res.data.total;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //添加粉丝特征
            addFanCharacteristics() {
                this.$router.push({
                    path: '/practice/fanCharacteristics/create'
                })
            },
            //编辑
            editFanCharacteristics(id) {
                this.$router.push({
                    path: '/practice/fanCharacteristics/create',
                    query: {
                        id: id
                    }
                })
            },
            //删除
            delFanCharacteristics(id) {
                this.$confirm(`是否删除该粉丝特征，删除将无法恢复`, '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass:'green-message-box',
                    type:'warning'
                }).then(()=>{
                    let param = {
                        id: id
                    }
                    fanDel(param).then((res) => {
                        this.$message.success(res.msg);
                        this.getFanCharacteristicsList();
                    }).catch((err) => {
                        console.log(err);
                    });
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            //分页
            fanCharacteristicsCurrentChange(val){
                this.fanCharacteristicsPages.currentPageNum = val;
                this.getFanCharacteristicsList();
            }
        }
    }
</script>

<style scoped lang="scss">
    .content-body {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        .header {
            display: flex;
            justify-content: flex-end;
            i {
                font-size: 12px;
                margin-right: 5px;
            }
        }
        .content-table {
            width: 100%;
            margin-top: 20px;
            flex: 1;
        }
    }
</style>